@use "styles/colours";
@use "styles/typography";
@use "styles/breakpoints";
@use "styles//vars";

.footer {
  background-color: colours.$darkGrey;

  &__container {
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: space-between;
  }

  &__col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (min-width: breakpoints.$md) {
      justify-content: flex-start;

      &:last-of-type {
        justify-content: flex-end;
        padding-left: 40px;
      }
    }
  }

  li {
    &:first-of-type {
      margin-right: 40px;
    }
  }

  &__item {
    @extend .button-price;
    position: relative;
    font-size: 12px;
    line-height: 16px;
    color: colours.$yellow;
    text-transform: uppercase;
    padding: 8px 0;
    @include vars.linkUnderline;

    @media (max-width: breakpoints.$md) {
      &::after {
        bottom: 3px;
      }
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }

    &--white {
      @extend .caption-two;
      color: colours.$white;
      text-transform: capitalize;
      padding: 8px 0;
    }
  }
}
