.base-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  &__page {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}
