@use "colours";

$font-path: "../assets/fonts";

$sini-bold: "Sini-Bold";
$sini-boldItalic: "Sini-BoldItalic";
$montserrat-bold: "Montserrat-Bold";
$montserrat-medium: "Montserrat-Medium";
$montserrat-regular: "Montserrat-Regular";
$montserrat-semibold: "Montserrat-SemiBold";

@font-face {
  font-family: $sini-bold;
  src: url("#{$font-path}/#{$sini-bold}.ttf") format("opentype");
}

@font-face {
  font-family: $sini-boldItalic;
  src: url("#{$font-path}/#{$sini-boldItalic}.ttf") format("opentype");
}

@font-face {
  font-family: $montserrat-bold;
  src: url("#{$font-path}/#{$montserrat-bold}.otf") format("opentype");
}

@font-face {
  font-family: $montserrat-medium;
  src: url("#{$font-path}/#{$montserrat-medium}.otf") format("opentype");
}

@font-face {
  font-family: $montserrat-regular;
  src: url("#{$font-path}/#{$montserrat-regular}.otf") format("opentype");
}

@font-face {
  font-family: $montserrat-semibold;
  src: url("#{$font-path}/#{$montserrat-semibold}.otf") format("opentype");
}

.large-title {
  font-family: $sini-bold;
  line-height: 32px;
  font-size: 32px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: colours.$darkGrey;
}

.large-product-title {
  font-family: $sini-boldItalic;
  line-height: 40px;
  font-size: 40px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: colours.$darkGrey;
}

.small-product-title {
  font-family: $sini-boldItalic;
  line-height: 24px;
  font-size: 24px;
  letter-spacing: 0.25px;
  color: colours.$darkGrey;
  text-transform: uppercase;
}

.title-one {
  font-family: $sini-bold;
  line-height: 28px;
  font-size: 28px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: colours.$darkGrey;
}

.title-two {
  font-family: $sini-bold;
  line-height: 24px;
  font-size: 24px;
  letter-spacing: 0.25px;
  color: colours.$darkGrey;
}

.title-three {
  font-family: $sini-bold;
  line-height: 20px;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: colours.$darkGrey;
}

.title-four {
  font-family: $sini-bold;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: colours.$darkGrey;
}

.headline {
  font-family: $montserrat-semibold;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0px;
  color: colours.$darkGrey;
}

.body {
  font-family: $montserrat-regular;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: colours.$darkGrey;
}

.body-bold {
  font-family: $montserrat-semibold;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: colours.$darkGrey;
}

.body-two {
  font-family: $montserrat-regular;
  line-height: 20px;
  font-size: 13px;
  letter-spacing: 0.25px;
  color: colours.$darkGrey;
}

.body-two-bold {
  font-family: $montserrat-semibold;
  line-height: 20px;
  font-size: 13px;
  letter-spacing: 0.25px;
  color: colours.$darkGrey;
}

.caption-one {
  font-family: $montserrat-semibold;
  line-height: 16px;
  font-size: 13px;
  letter-spacing: 0.25px;
  color: colours.$midGrey;
}

.caption-two {
  font-family: $montserrat-regular;
  line-height: 16px;
  font-size: 13px;
  letter-spacing: 0.25px;
  color: colours.$midGrey;
}

.viewcart-buttonprice {
  font-family: $montserrat-bold;
  line-height: 16px;
  font-size: 24px;
  letter-spacing: 0.25px;
}

.button-price {
  font-family: $montserrat-bold;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.badge-text {
  font-family: $montserrat-bold;
  line-height: 14px;
  font-size: 11px;
  letter-spacing: 0px;
}
