@use "breakpoints";
@use "colours";
@use "vars";
@use "typography";

// adds animation to elements
* {
  transition: all 0.3s ease;
}
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin: auto;
  max-width: 567px;
  padding: 24px 16px 24px;
  box-sizing: border-box;
  overflow: visible;
  flex-grow: 1;

  &--skinny {
    max-width: 568px;
  }


  &__col {
    width: 100%;

    &--single {
      // fix for registartion view
      width: calc(100vw - 32px);
      max-width: 100%;
      flex-grow: 1;
    }
  }

  @media (min-width: breakpoints.$md) {
    flex-direction: row;
    max-width: 100%;
    padding: 24px;

    &--skinny {
      max-width: 568px;
    }

    &__row {
      display: flex;
      flex-direction: column;
    }

    &__col {
      width: 50%;
      max-width: 50%;

      &:first-of-type {
        padding-right: 28px;
        width: calc(50% - 28px);
      }
      &:nth-of-type(2) {
        padding-left: 28px;
        width: calc(50% - 28px);
      }

      &--single:first-of-type {
        max-width: 568px;
        //max-width: $md;
        width: 100%;
        padding-right: 0;
      }
    }

    &--header {
      .main-wrapper__col {
        padding: 0;
        width: 65%;
        max-width: 100%;
      }
    }
  }

  @media (min-width: breakpoints.$lg) {
    max-width: 1216px;
    padding: 40px 32px 40px;
    margin: 0 auto;

    &__row {
      width: 100%;
    }

    &--narrow {
      padding-left: 0;
      padding-right: 0;
      width: breakpoints.$md;
    }

    &--header {
      padding: 40px 32px;

      .main-wrapper__col {
        width: 764px;
      }
    }

    &--skinny {
      //margin-top: 24px;
      max-width: 568px;
      //padding: 0px;
    }
  }
  @media only screen and (min-width: 768px) {
    &--header {
      .main-wrapper__col {
        max-width: 535px;
      }
    }
  }
}

.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 8px 16px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.gradient_line {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(to right, white 0 20%, #e0e0e0);
  border-image-slice: 1;

  &--dark {
    border-bottom: 1px solid;
    border-image-source: linear-gradient(to right, #535353 0 20%, colours.$darkGrey);
    border-image-slice: 1;
  }
}

.overlay {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  background-color: colours.$blackOpacity;
}

.divider {
  background-color: colours.$lightGrey;
  height: 1px;
}

.sticky-button {
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: colours.$white;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.05),
      0 -8px 16px 0 rgba(0, 0, 0, 0.05);
  }
}

.prevent-text-wrap * {
  white-space: nowrap;
  width: max-content;
}

.u-main-background {
  @include vars.mainBackground;
}

.u-flex {
  flex: 1;
}

.u-margin-bottom {
  margin-bottom: 16px;
}
.u-padding {
  padding: 16px;
}

.error-style {
  &-caption {
    @extend .caption-one;
    color: colours.$red;
  }
}
