@use 'colours';

$tripleBoxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),
  0 8px 16px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.05);

@mixin tripleBoxShadow($colorLevel: 0.05) {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, $colorLevel),
    0 8px 16px 0 rgba(0, 0, 0, $colorLevel),
    0 2px 4px 0 rgba(0, 0, 0, $colorLevel);
}

@mixin mainBackground {
  background-image: url("../assets/images/BG-Texture_v1.png");
  background-repeat: repeat;
  background-size: auto;
}

@mixin gradientLine {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(to right, white 0 20%, #e0e0e0);
  border-image-slice: 1;
}

@mixin overlay {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  background-color: colours.$blackOpacity;
}

// animated underline on hover,
// parent that @includes needs to have position: relative
// safari requires border to exist before hover -> initially border-width must be 1px
@mixin linkUnderline($color: colours.$yellow, $width: 0) {
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: $width;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    bottom: -6px;
    transition: all 0.3s ease;
    margin-left: 50%;
  }
  &:hover {
    &::after {
      width: 100%;
      border-width: 1px;
      border-color: $color;
      margin-left: 0;
    }
  }
}

// wraps and truncates text, by defult on first line
@mixin wrap-and-truncate($lineWrap: 1) {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: $lineWrap;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
