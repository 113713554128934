$white: #ffffff;
$black: #000000;
$yellow: #ffd204;
$starburst: #ffeca9;
$starburst30: rgba(255, 236, 169, 0.3);
$starburst60: rgba(255, 236, 169, 0.6);
$darkGrey: #171717;
$midGrey: #646464;
$uiGrey: #7e7e7e;
$switchGrey: #999999;
$lightGrey: #e0e0e0;
$lightestGrey: #f8f8f8;
$red: #d70015;
$darkRed: #bd0012;
$green: #208925;
$lightGreen: #34c759;
$blackOpacity: rgba($darkGrey, 0.7);
$yellowOpacity: rgba(255, 210, 4, 0.7);
$shadow: #f1f1f1;
$shadowGrad: #fbfbfb;
