@use "styles/colours";
@use "styles/breakpoints";
@use "styles/typography";
@use "styles/shared";

.order-setup-no-delivery {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100%;
  height: auto;
  padding-bottom: 87px;
  overflow: auto;
  max-height: auto;
  box-sizing: border-box;

  @media (min-width: breakpoints.$md) {
    height: 100%;
    min-width: 760px;
    width: 760px;
    padding-bottom: 0;
  }

  &__button {
    width: 100%;
    flex-direction: row;
    display: flex;

    &-wrapper {
      @media (min-width: 768px) {
        flex: 1;
        margin: 16px 24px;
      }
    }
  }

  &__error-message {
    @extend .error-style-caption;
    margin-top: 4px;
    font-size: 12px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 32px;
    justify-content: space-between;
    align-items: center;
  }

  &__invisible {
    opacity: 0;
  }
}